import React from "react";
import heroImg from "./images/hero.jpeg";

function App() {
  return (
    <div className="min-h-[100vh] flex flex-col">
      <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full border-b border-gray-200 text-sm py-3 sm:py-0 bg-gradient-to-r from-blue-950 to-blue-800">
        <nav
          className="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
          aria-label="Global"
        >
          <div className="w-full flex justify-center pt-3 pb-3">
            <h2 className="text-4xl text-white font-bold">ProCabinetry</h2>
          </div>
        </nav>
      </header>

      <div
        style={{
          backgroundImage: `url(${heroImg})`,
          backgroundPosition: "center",
          height: "400px",
        }}
      ></div>

      <main className="flex-grow space-y-5 md:space-y-8 px-8">
        <div className="space-y-3 my-6">
          <p className="text-lg text-center">
            Our team specialize in kitchen installation services, offering a
            range of options including{" "}
            <span className="text-blue-800 font-bold ">IKEA installation</span>,{" "}
            <span className="text-blue-800 font-bold">
              custom kitchen designs
            </span>
            , <span className="text-blue-800 font-bold">floating shelves</span>,{" "}
            <span className="text-blue-800 font-bold">
              closet installations
            </span>
            ,{" "}
            <span className="text-blue-800 font-bold">
              and vanity installations
            </span>
            .
          </p>
          <p className="text-lg text-center">
            We provide comprehensive support from the initial planning and
            design phase to the actual installation process, ensuring that every
            detail is tailored to your specific needs and preferences.
          </p>
          <p className="text-lg text-center">
            Our team is dedicated to delivering{" "}
            <span className="text-blue-800 font-bold">
              high-quality results
            </span>{" "}
            and{" "}
            <span className="text-blue-800 font-bold">
              customer satisfaction
            </span>
            . If you're looking for simple{" "}
            <span className="text-blue-800 font-bold">IKEA installation</span>{" "}
            or a{" "}
            <span className="text-blue-800 font-bold">
              fully customized kitchen design
            </span>
            , ProCabinetry has the expertise and resources to{" "}
            <span className="text-blue-800 font-bold">
              bring your dream kitchen to life
            </span>
            .
          </p>
        </div>
      </main>

      <footer className="w-full p-8 bg-gradient-to-r from-blue-950 to-blue-800">
        <div className="grid lg:grid-cols-3 md:grid-cols-1 lg:justify-items-center gap-6">
          <div className="grid sm:grid-cols-2 gap-4 sm:gap-6 md:gap-8 lg:gap-12">
            <div className="flex gap-4">
              <svg
                className="flex-shrink-0 size-5 text-gray-500 dark:text-neutral-300"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path>
                <circle cx="12" cy="10" r="3"></circle>
              </svg>

              <div className="grow">
                <p className="text-sm text-neutral-300">United States</p>
                <address className="mt-1 not-italic font-bold text-white">
                  Austin, Texas
                </address>
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <svg
              className="flex-shrink-0 size-5 text-neutral-300"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z"></path>
              <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10"></path>
            </svg>

            <div className="grow">
              <p className="text-sm text-neutral-300">Email us</p>
              <p>
                <a
                  className="relative inline-block font-bold focus:outline-none text-white"
                  href="mailto:procabinetryllc@gmail.com"
                >
                  procabinetryllc@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className="flex gap-4">
            <svg
              className="flex-shrink-0 size-5 text-neutral-300"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>

            <div className="grow">
              <p className="text-sm text-neutral-300">Call us</p>
              <p>
                <a
                  className="relative inline-block font-bold focus:outline-none text-white"
                  href="tel:15129098409"
                >
                  +1(512)909-84-09
                </a>
              </p>
              <p>
                <a
                  className="relative inline-block font-bold focus:outline-none text-white"
                  href="tel:15124664626"
                >
                  +1(512)466-46-26
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
